<template>
  <!-- qr-code START -->
  <div class="qr-code" v-if="userToken && userLang">
    <!-- qr-code:headline:description START -->
    <div class="headline-description">
      <h1 v-html="`${$t('sms.qrcode.headline')}`"></h1>
      <div class="description" v-html="`${$t('sms.qrcode.description')}`"></div>
    </div>
    <!-- qr-code:headline:description END -->

    <!-- qr-code:qr-codes START -->
    <div class="qr-codes">
      <div class="qr download">
        <div class="inner">
          <img :src="`${rootUrl}/qr/?d=${userToken}&fg=0D3E5C`"/>
          <div
            class="button"
            v-on:click="downloadFileDirect(
              `${rootUrl}/qr/?d=http://anybox-www.int5.syreta.com/sms-check-in/?token=${userToken}&locale=${userLang}&fg=0D3E5C&download=1`
            )"
          >
            <div class="icon"></div>
            <div class="content">
              <div class="text" v-html="$t('sms.qrcode.headline')"></div>
              <div class="text highlighted" v-html="$t('outdoor.index.appdownload')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- qr-code:qr-codes END -->

  </div>
  <!-- qr-code END -->
</template>

<script>
export default {
  name: 'qr-code',
  data() {
    return {
      /**
       * Params
       */
      userToken: this.$route.query ? this.$route.query.token : undefined,
      userLang: this.$route.query ? this.$route.query.locale : undefined,
      /**
       * Root URL
       */
      rootUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    downloadFile(path, label, root) {
      // v-on:click="downloadFile(
      //   `${rootUrl}/qr/?d=http://anybox-www.int5.syreta.com/sms-check-in/?token=${userToken}&locale=${userLang}&fg=0D3E5C`,
      //   `${$date(new Date()).format('DD.MM.YYYY')}`,
      //   `${rootUrl}`
      // )"
      this.axios.get(path, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'image/png' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `${root}-${label}.png`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
    },
    downloadFileDirect(path) {
      window.open(path);
    },
  },
};
</script>
